import React, { useState } from "react";
import { Section } from "../components/Layout";
import { Text } from "../components/Text";

export default function Maintenance() {
	const [deployToken, setDeployToken] = useState('');

	const link = (ref: string) => `https://gitlab.com/api/v4/projects/38295098/ref/${ref}/trigger/pipeline?token=${deployToken}`

	return <Section direction="vertical">
		<Text>Token</Text>
		<input type="text" value={deployToken} onChange={e => setDeployToken(e.target.value)} />
		<Text>Deploy</Text>
		<Section direction="horizontal">
			<button onClick={() => fetch(link('devel'), { method: 'POST' })}>landingpage.kofidal.eu</button>
			<img src="https://gitlab.com/h1620/landingpage/badges/devel/pipeline.svg" />
		</Section>
		<Section direction="horizontal">
			<button onClick={() => fetch(link('master'), { method: 'POST' })}>konfidal.eu</button>
			<img src="https://gitlab.com/h1620/landingpage/badges/master/pipeline.svg" />
		</Section>
	</Section>
}
